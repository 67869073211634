<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/MIC-25-CON-PENNELLATE-1024x696.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                IDC Solutions 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/automatic_solutions">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/Extreme-rev.1-210607-1024x696.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Automatic Solutions
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/crimpingunit">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/KN21-REV1-210607-1024x696.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Crimping Unit
              </h4>
            </div>
          </div>
          </router-link>
        </div>
          <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/accessoiries">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/KM100-rev.1-210607-768x522.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              Accessoiries
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/mini_applicators">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/07/Miniapplicatore-Meccanico.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Mini Applicatores 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../components/Header.vue";

import Footer from "../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>